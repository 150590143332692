<template>
  <div class="left-section">
    <div class="d-flex flex-column pt-5" style="width: 320px;">
      <span
        style="color: #6038F7; font-size: 20px; font-family: 'Poppins-Medium'; margin-bottom: 12px;"
        >Search results</span
      >
      <search-list-card
        :title="category.title"
        :image="category.image"
        v-for="category in categories"
        :key="category.title"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SearchListCard from "./SearchListCard.vue";
export default {
  components: { SearchListCard },
  data() {
    return {
      categories: [
        {
          title: "All",
          image: require("@/assets/svg/search/icon_all.svg"),
        },
        {
          title: "Volunteer",
          image: require("@/assets/svg/search/icon_volunteer.svg"),
        },
        {
          title: "Agency",
          image: require("@/assets/svg/search/icon_agency.svg"),
        },
        {
          title: "Needs",
          image: require("@/assets/svg/search/icon_needs.svg"),
        },
        {
          title: "Events",
          image: require("@/assets/svg/search/icon_events.svg"),
        },
        {
          title: "Blogs",
          image: require("@/assets/svg/search/icon_blogs.svg"),
        },
      ],
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      sideSection: "sideSection",
    }),
  },
  methods: {
    ...mapActions("auth", {
      getLeftSectionInfo: "getLeftSectionInfo",
    }),
    goToVolunteers() {
      if (this.$route.name != "agency-volunteers") {
        this.$router.push({ name: "agency-volunteers" });
      }
    },
    goToStore() {
      if (this.$route.name != "agency-shop") {
        this.$router.push({ name: "agency-shop" });
      }
    },
    goToAgencies() {
      if (this.$route.name != "agencies") {
        this.$router.push({ name: "agencies" });
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getLeftSectionInfo()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 70px;
  max-width: 430px;
  min-width: 430px;
  width: 430px;
  padding-left: 100px;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
